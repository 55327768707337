import classnames from 'classnames';
import React, {useRef, useEffect} from 'react';
import {DotNavigation} from 'wix-ui-tpa/cssVars';
import {Experiments} from '../../../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {classes as sliderGalleryStyleable} from './PaginationDots.st.css';
import s from './PaginationDots.scss';

export interface PaginationDotsProps {
  swiperCurrentIndex: number;
  totalProducts: number;
  slidesCount: number;
  shouldLoop: boolean;
  productsAmountPerSwipe: number;
}

export enum PaginationDotsDataHooks {
  PaginationDots = 'PaginationDotsDataHooks.Dots',
  PaginationDotsWrapper = 'PaginationDotsDataHooks.Wrapper',
}

const MAX_DOTS_AMOUNT = 5;
const CENTER_DOT_MIN_INDEX = 3;
const LOOPING_PADDING_DOTS_AMOUNT = 9;
const LOOPING_MIN_DOTS_AMOUNT = MAX_DOTS_AMOUNT + LOOPING_PADDING_DOTS_AMOUNT;

export const PaginationDots = ({
  swiperCurrentIndex,
  totalProducts,
  slidesCount,
  shouldLoop,
  productsAmountPerSwipe,
}: PaginationDotsProps) => {
  const {experiments} = useExperiments();
  const rootRef = useRef<HTMLDivElement>(null);
  const showPaginationDotsCustomStyle = experiments.enabled(Experiments.ShowPaginationDotsCustomStyle);
  const shouldHideDots = showPaginationDotsCustomStyle && totalProducts <= slidesCount;

  const getDotAmount = () => {
    const swipesAmount = Math.ceil((totalProducts - (slidesCount ?? 0)) / productsAmountPerSwipe + 1);
    if (showPaginationDotsCustomStyle) {
      return shouldLoop ? Math.max(swipesAmount + LOOPING_PADDING_DOTS_AMOUNT, LOOPING_MIN_DOTS_AMOUNT) : swipesAmount;
    }
    return totalProducts;
  };

  const getSelectedDotIndex = () => {
    if (showPaginationDotsCustomStyle) {
      return shouldLoop ? swiperCurrentIndex + CENTER_DOT_MIN_INDEX : swiperCurrentIndex;
    }
    return swiperCurrentIndex;
  };

  const ArrowContainerHeightsFixEnabled = experiments.enabled(Experiments.ArrowContainerHeightsFix);

  // istanbul ignore next: cant test with jsdom, tested by sled
  useEffect(() => {
    const rootWidth = rootRef?.current?.clientWidth;
    if (!rootWidth) {
      return;
    }
    setTimeout(() => rootRef.current?.classList.remove(s.hideDotsOnSSR), 0);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rootRef, rootRef?.current, rootRef?.current?.clientWidth]);

  return (
    <div
      data-hook={PaginationDotsDataHooks.PaginationDotsWrapper}
      role="navigation"
      ref={rootRef}
      className={classnames(s.dotNavigationWrapper, {
        [s.paginationDotsCustomStyle]: showPaginationDotsCustomStyle,
        [s.showDots]: !shouldHideDots,
        [s.sidePadding]: ArrowContainerHeightsFixEnabled,
        [s.hideDotsOnSSR]: showPaginationDotsCustomStyle,
        [sliderGalleryStyleable.root]: showPaginationDotsCustomStyle,
      })}>
      <DotNavigation
        className={'pagination-dots'}
        aria-label={swiperCurrentIndex.toString()}
        data-hook={PaginationDotsDataHooks.PaginationDots}
        currentIndex={getSelectedDotIndex()}
        length={getDotAmount()}
        showBorder={showPaginationDotsCustomStyle}
      />
    </div>
  );
};
