/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {ReactNode, useEffect, useState} from 'react';
import s from './ArrowsNavigationWrapper.scss';
import {classes} from './ArrowsNavigationWrapper.st.css';
import {IconButton} from 'wix-ui-tpa/cssVars';
import {ChevronLeft, ChevronRight} from '@wix/wix-ui-icons-common/on-stage';
import {withGlobals} from '../../../../globalPropsContext';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ISliderGlobalProps} from '../../../sliderGlobalStrategy';
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import {Experiments, keyboardEvents} from '../../../../constants';
import {useStylesParams} from '../../../../stylesParamsContext';
import {useStyles} from '@wix/tpa-settings/react';
import {ConditionalRender} from '../../../../category/components/ConditionalRender/ConditionalRender';

export type ArrowsNavigationWrapperProps = {
  navigateNext(): void;
  navigatePrev(): void;
  arrowsContainerHeight?: number;
  hasPrevItems: boolean;
  hasNextItems: boolean;
  children: React.ReactNode;
} & ISliderGlobalProps;

export enum ArrowsNavigationWrapperDataHook {
  Root = 'ArrowsNavigationWrapperDataHook.Root',
  LeftArrow = 'ArrowsNavigationWrapperDataHook.LeftArrow',
  LeftArrowContainer = 'ArrowsNavigationWrapperDataHook.LeftArrowContainer',
  RightArrow = 'ArrowsNavigationWrapperDataHook.RightArrow',
  RightArrowContainer = 'ArrowsNavigationWrapperDataHook.RightArrowContainer',
}

type ArrowContainerProps = {
  className: string;
  isVisible: boolean;
  dataHook: string;
  children: ReactNode;
};
const ArrowContainer = ({className, isVisible, dataHook, children}: ArrowContainerProps) => {
  const {experiments} = useExperiments();
  const hideArrowsOnSliderGalleryWithFewerProductsThanColumns = experiments.enabled(
    Experiments.HideArrowsOnSliderGalleryWithFewerProductsThanColumns
  );

  return (
    <ConditionalRender
      by={'gallery_showSliderNavigationArrows'}
      className={classNames(s.arrowContainer, className, {
        [s.fixVisibility]: hideArrowsOnSliderGalleryWithFewerProductsThanColumns,
        [s.visible]: isVisible,
      })}
      dataHook={dataHook}>
      {children}
    </ConditionalRender>
  );
};

export const ArrowsNavigationWrapper: React.FC<ArrowsNavigationWrapperProps> = withGlobals(
  withTranslations()(
    ({
      children,
      globals,
      navigateNext,
      navigatePrev,
      arrowsContainerHeight,
      hasPrevItems,
      hasNextItems,
    }: // eslint-disable-next-line sonarjs/cognitive-complexity
    ArrowsNavigationWrapperProps) => {
      const {sliderGalleryPreviousProduct, sliderGalleryNextProduct} = globals.textsMap;
      const {isRTL, isMobile} = useEnvironment();

      const leftArrowLabel = isRTL ? sliderGalleryNextProduct : sliderGalleryPreviousProduct;
      const rightArrowLabel = isRTL ? sliderGalleryPreviousProduct : sliderGalleryNextProduct;

      const leftArrowClick = () => (isRTL ? navigateNext() : navigatePrev());
      const rightArrowClick = () => (isRTL ? navigatePrev() : navigateNext());
      const [leftArrowVisibility, setLeftArrowVisibility] = useState<boolean>(false);
      const [rightArrowVisibility, setRightArrowVisibility] = useState<boolean>(false);

      const stylesParams = useStylesParams();
      const styles = useStyles();

      const shouldAlwaysShowArrows = isMobile || !styles.get(stylesParams.gallery_sliderShowArrowsOnHoverOnly);

      useEffect(() => {
        if (!arrowsContainerHeight) {
          return;
        }

        setBothArrowsVisibility(shouldAlwaysShowArrows);
      }, [shouldAlwaysShowArrows, arrowsContainerHeight]);

      /* istanbul ignore next: tested in sled */
      const inlineCssVars = {
        '--arrowsContainerHeight': arrowsContainerHeight ? `${arrowsContainerHeight}px` : 'unset',
      };

      const setBothArrowsVisibility = (visibility: boolean) => {
        setLeftArrowVisibility(visibility);
        setRightArrowVisibility(visibility);
      };

      const showOnlyLeftArrow = () => {
        setLeftArrowVisibility(true);
        setRightArrowVisibility(false);
      };

      const showOnlyRightArrow = () => {
        setLeftArrowVisibility(false);
        setRightArrowVisibility(true);
      };

      const onMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
        const bounds = event.currentTarget.getBoundingClientRect();
        const mouseX = event.clientX - bounds.left;
        if (mouseX > bounds.width / 2) {
          showOnlyRightArrow();
          return;
        }

        showOnlyLeftArrow();
      };

      const onMouseLeave = () => setBothArrowsVisibility(false);

      const onKeypressNavigation = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === keyboardEvents.ARROW_RIGHT.keyCode) {
          rightArrowClick();
        }
        if (e.keyCode === keyboardEvents.ARROW_LEFT.keyCode) {
          leftArrowClick();
        }
      };

      const rightArrowActive = hasNextItems && rightArrowVisibility;
      const leftArrowActive = hasPrevItems && leftArrowVisibility;

      return (
        <div
          data-hook={ArrowsNavigationWrapperDataHook.Root}
          className={s.root}
          style={inlineCssVars as React.CSSProperties}
          role={'group'}
          tabIndex={0}
          onKeyDown={onKeypressNavigation}
          {...(shouldAlwaysShowArrows ? {} : {onMouseOver, onMouseLeave})}>
          <ArrowContainer
            className={s.left}
            isVisible={leftArrowActive}
            dataHook={ArrowsNavigationWrapperDataHook.LeftArrowContainer}>
            <IconButton
              aria-label={leftArrowLabel}
              className={classNames(classes.root, s.button)}
              data-hook={ArrowsNavigationWrapperDataHook.LeftArrow}
              icon={<ChevronLeft className={s.svgIcon} />}
              onClick={leftArrowClick}
              tabIndex={leftArrowActive ? 0 : -1}
            />
          </ArrowContainer>

          <div className={s.sliderWrapper}>{children}</div>

          <ArrowContainer
            className={s.right}
            isVisible={rightArrowActive}
            dataHook={ArrowsNavigationWrapperDataHook.RightArrowContainer}>
            <IconButton
              aria-label={rightArrowLabel}
              className={classNames(classes.root, s.button)}
              data-hook={ArrowsNavigationWrapperDataHook.RightArrow}
              icon={<ChevronRight className={s.svgIcon} />}
              onClick={rightArrowClick}
              tabIndex={rightArrowActive ? 0 : -1}
            />
          </ArrowContainer>
        </div>
      );
    }
  )
);
